var URL = window.location.href.split('/') [3];
// edgeHack
function edgeHack() {
  const clone = $(this).clone();
  $(this).replaceWith(clone);
}
// UA
const ua = window.navigator.userAgent.toLowerCase();
if (
  ua.indexOf('iphone') > 0 ||
  (ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0)
) {
  // SP
  $('body').addClass('-sp');
} else if (ua.indexOf('ipad') > 0 || ua.indexOf('android') > 0) {
  // TAB
  $('body').addClass('-tab');
} else if (ua.indexOf('msie') !== -1 || ua.indexOf('trident') !== -1) {
  $('body').addClass('-pc -ie');
} else if (ua.indexOf('edge') !== -1) {
  $('body').addClass('-pc -edge');
  $('.gnav__children').each(edgeHack);
} else if (ua.indexOf('chrome') !== -1) {
  $('body').addClass('-pc -chrome');
} else if (ua.indexOf('safari') !== -1) {
  $('body').addClass('-pc -safari');
} else if (ua.indexOf('firefox') !== -1) {
  $('body').addClass('-pc -firefox');
} else if (ua.indexOf('opera') !== -1) {
  $('body').addClass('-pc -opera');
} else {
  $('body').addClass('-else');
}
// spNav
function spNav() {
  $('body').toggleClass('spnav-isopen');
  $(this)
    .next()
    .fadeToggle();
}
$('.l-header .-toggle').on('click', spNav);
// smoothScroll
function pageTop() {
  $('html, body').animate({ scrollTop: 0 }, { duration: 300 });
}
$('.pagetop').on('click', pageTop);

function smooth() {
  var href= $(this).attr("href");
  var target = href === '#' || href === '' ? $('html') : $(document).find(href);
  var headerHeight = $('.l-header').outerHeight();
  var position = target.offset().top - headerHeight;
  if (URL == 'index.html' || URL == '' || URL == '/') {
    $('html, body').animate({ scrollTop: position - 50 });
  } else {
    $('html, body').animate({ scrollTop: position });
  }
  return false;
}
$('a[href*="#"]').on('click', smooth);
// レスポンシブ
if ($('.-toggle').is(':visible')) {
  // SP TAB-port
} else {
  // TAB-land PC
  $('body').addClass('-toggleHidden');
}
// レスポンシブリサイズ
let timer = false;
$(window).on('resize', function() {
  if (timer !== false) {
		clearTimeout(timer);
	}
	timer = setTimeout(function() {
    timer = false;
		if ($('.-toggle').is(':visible')){
      //SP tabPort
      $('body').removeClass('-toggleHidden');
		}else{
      //tabLand PC
      $('body').addClass('-toggleHidden');
		}
	},200);
});
$(document).on('click', 'body.spnav-isopen', function(e) {
  if (!$(e.target).closest('.l-header').length) {
    console.log('outClick');
    $('body').removeClass('spnav-isopen');
    $('.gnav').fadeOut();
  }
});
